




import {Component, Vue} from 'vue-property-decorator';
import {Action} from 'vuex-class';
import {debug} from "webpack";

@Component
export default class Callback extends Vue {
  @Action('oidcSignInCallback') oidcSignInCallback!: any;

  constructor() {
    super();
    // const userManager = new UserManager({
    //     userStore: new WebStorageStateStore({store: window.localStorage})
    // });
    // userManager
    //     .signinRedirectCallback()
    //     .then((user: User) => {
    //         window.location.href = '/';
    //     })
    //     .catch(error => {
    //         console.log('Error', error);
    //     });
  }

  mounted() {
    this.oidcSignInCallback()
        .then((redirectPath: string) => {
          console.log(redirectPath);
          this.$router.push(redirectPath);
        })
        .catch((err: any) => {
          console.log(err)
          this.$router.push('/oidc-callback-error'); // Handle errors any way you want
        });
  }
}
